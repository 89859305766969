import React from 'react';
import Home from '../src/Pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
   <Home />
  );
}

export default App;
